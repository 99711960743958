<template>
  <page-container>
    <page-header title="Literature" />
    <div class="d-flex flex-wrap">
      <v-card class="elevation-12 mx-auto my-4" :width="500" v-for="pdf in pdfList" :key="pdf.pdf">
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4" style="line-height: 1rem;">
              {{ pdf.outline }}
            </div>
            <v-list-item-title class="text-h5 mb-1" style="white-space: normal;">
              {{ pdf.title }}
            </v-list-item-title>
            <v-list-item-subtitle style="white-space: normal;">{{ pdf.publisher }}</v-list-item-subtitle>
            <v-btn outlined rounded text color="primary" @click.stop="openPDFDialog(pdf.title, pdf.pdf)">
              Read Now
            </v-btn>
          </v-list-item-content>

          <v-list-item-avatar tile size="200">
            <v-img :src="require(`@/assets/img/literature/${pdf.preview}`)"></v-img>
          </v-list-item-avatar>

        </v-list-item>
      </v-card>
    </div>
    <v-dialog v-model="pdfDialog" persistent overlay-color="secondary darken-4" overlay-opacity=".4"
      transition="dialog-bottom-transition" :width="$vuetify.breakpoint.mdAndUp ? '70vw' : '90vw'" >
      <v-card>
        <v-toolbar dark>
          <span>{{ selectedTitle }}</span>
          <v-spacer></v-spacer>
          <v-btn icon dark @click.stop="pdfDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <iframe ref="embedPDF" class="mx-auto" style="border: none" :height="500"
          :width="'100%'"></iframe>
      </v-card>
    </v-dialog>
  </page-container>
</template>

<script>
export default {
  data: () => ({
    pdfDialog: false,
    pdfList: [
      {
        title: 'মনখারাপের পাড়া (১ম খন্ড)',
        publisher: 'নির্মুখোশ, শারদ ১৪২৯',
        outline: 'Down the memory lane',
        pdf: '1DNfetdzkA1b09DfJpQr8vkcxcQr4lI--',
        preview: 'mon-kharaper-para-1__preview.png'
      },
      {
        title: 'মনখারাপের পাড়া (২য় খন্ড)',
        publisher: 'নির্মুখোশ, শারদ ১৪৩০',
        outline: 'Down the memory lane',
        pdf: '11BZ65UppJOlzi528-A7tJCUSquOcDfXx',
        preview: 'mon-kharaper-para-2__preview.png'
      },
      {
        title: 'ষোলো-আনা জীবনের দিকে',
        publisher: 'মেস-হোস্টেল ঘটিত এ বাঙালি জীবন',
        outline: 'Life at Mess-Hostel-Boarding',
        pdf: '1blTK3MSYwX5mFg5AkDSecZfV7bn352zd',
        preview: 'sholo-aana-jiboner-dike__preview.png'
      }
    ],
    selectedTitle: ''
  }),
  computed: {},
  watch: {
    pdfDialog () {
      if (!this.pdfDialog) {
        this.selectedTitle = ''
        this.$refs.embedPDF.src = ''
      }
    }
  },
  methods: {
    openPDFDialog (title, pdf) {
      this.pdfDialog = true
      setTimeout(() => {
        this.selectedTitle = title
        this.$refs.embedPDF.src = `//drive.google.com/file/d/${pdf}/preview`
      }, 1)
      // const pdfLink = require(`@/assets/literature/pdf/${pdf}`)
      // window.open(pdfLink + '#toolbar=0', '_blank')
    }
  },
  components: {
    PageContainer: () => import('@/components/reusable/PageContainer'),
    PageHeader: () => import('@/components/reusable/PageHeader')
  }
}
</script>
